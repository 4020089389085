<template>
  <div class="askmain">
      <div class="topbg">
        <p>三重认证严选律师，专注为您解决法律问题</p>
        <div class="imgbox1">
            <img src="@/assets/img/ls.png" alt="" class="img1">
            <img src="@/assets/img/ls.png" alt="" class="img2">
            <img src="@/assets/img/ls.png" alt="" class="img3">
            <span>等多位律师在线为您解决法律问题</span>
        </div>
      </div>
      <!-- 提问 -->
      <div class="twbox base_w">
        <div class="common_title_box">
          <span class="title">提问</span>
        </div>
        <zixun :type="3"></zixun>
      </div>
  </div>
</template>

<script>
import zixun  from '@/components/zixun'
export default {
  components: {zixun},
  data() {
      return {

      };
  },
  created() {},
  mounted() {
     // 跳转回顶部
     document.documentElement.scrollTop = 0;
  },
  computed: {
    
  },
  watch: {},
  methods: {},
 
 
  }
</script>
<style lang='scss' scoped>
.askmain{
  .topbg{
    height: 320px;
    background: url('../../assets/img/bg3.png') no-repeat;
    background-size: 100% 100%;
    text-align: center;
    p{
      font-size: 38px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #3D4962;
      letter-spacing: 3px;
      padding-top: 56px;
      padding-bottom: 33px;
    }
    .imgbox1{
      position: relative;
      img{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        position: absolute;
      }
      .img1{
        top: 0;
        left: 41%;
      }
      .img2{
        top: 0;
        left: 42%;
      }
      .img3{
        top: 0;
        left: 43%;
      }
      span{
        position: absolute;
        top: 0;
        left: 45%;
        display: inline-block;
        height: 27px;
        line-height: 27px;
        font-size: 16px;
        color: #60666F;
      }
    }
  }

  .twbox{
    height: 520px;
    background-color: #fff;
    box-shadow: 0px 7px 20px 0px rgba(34,42,53,0.08);
    border-radius: 10px;
    padding: 27px 30px;
    // transform: translateY(-77px);
    margin-top: -77px;
    margin-bottom:80px;
    .common_title_box{
      margin-bottom: 19px;
    }
  }
}

</style>